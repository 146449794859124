.container-404 {
  width: 1094px;
  height: 40.125rem;
  border-radius: 1.75rem;
  background: #fff;
  box-shadow: 0px 3.3299834728240967px 5.2328314781188965px 0px rgba(0, 0, 0, 0.02);
}

.heading-404 {
  width: 547px;
  height: 269.347px;
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 280px;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(180deg, var(--primary-color) 0%, #fdfdfd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subheading-404 {
  width: 35.5rem;
  color: var(--ui-colours-blue-gray, #64748b);
  font-family: Plus Jakarta Sans;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;
}

.body-404 {
  width: 32.875rem;
  color: #b0b0b0;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}

.btn-404 {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.3125rem;
  width: 10em;
}

.btn-404.primary {
  border: none;
  background: var(--primary-color);
  color: #fff;
}

.btn-404.white {
  border: 1px solid var(--gray-200, #e2e8f0);
  background: var(--white-white, #fff);
  color: #334155;
}

.img-container {
  position: relative;
}

.color-img-container {
  width: 502px;
  height: 645px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 0px 28px 28px 0px;
}

.color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 0.8;
  z-index: 1;
  border-radius: inherit;
}

.laptop-img-container {
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 14px 64px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  place-content: center;
  place-items: center;
}
