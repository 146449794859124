.popover {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
  display: none;
}

.popover ul {
  list-style: 'none';
  padding: '0';
  font-size: '1rem';
  margin: '0';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popover-list-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.popover ul {
  list-style: 'none';
  padding: 0;
  margin: 0;
}

.popover li {
  display: flex;
  padding: 10px 0 10px 0.5rem;
  width: 100%;
  cursor: pointer;
  text-align: left;
  align-items: center;
  font-size: 1rem;
}

.popover hr {
  border-top: 1px solid white;
  margin: 0;
  padding: 0;
  width: 100%;
}

.popover-delete:hover {
  background-color: #c60000 !important;
}

.popover-link {
  text-decoration: none;
  color: #fff;
}
.popover-link:hover {
  text-decoration: none;
  color: #fff;
}
