.ibis-slider {
  -webkit-appearance: none;
  width: 55%;
  height: 0.25rem;
  background: #aab2b2;
  outline: none;
  z-index: 10;
}

.ibis-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.ibis-slider::-moz-range-thumb {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: var(--primary-color);
}
