@keyframes shine {
  0% {
    background-position: right;
  }
}

.shine-overlay {
  animation: shine 1s infinite;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.3) 50%, #0000 66%);
  background-size: 300% 100%;
  z-index: 10;
}
