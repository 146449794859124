.test-list-item {
  padding: 10px;
  cursor: pointer;
}

.test-list-item:hover {
  background-color: lightblue;
}

.test-list-item:nth-of-type(even) {
  background-color: #faf9f6;
}

.test-list-item:hover {
  background-color: lightblue;
}

.test-list-item.highlighted {
  background-color: lightblue;
  color: black;
}

.company-test-btn {
  width: 140px;
  position: relative;
}

.company-test-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
